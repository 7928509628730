import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import { Articles } from "../components/ArticlePage/others"
import { NavContainer } from "../components/BrandPage/brand-page.css"
import { Container, Title } from "../components/Hero/hero.css"
import Newsletter from "../components/Newsletter/newsletter"
import BreadCrumb from "../components/Breadcrumb/breadcrumb"


import {
  ArticlesContainer,
} from "../components/ArticlePage/others.css"

const LatestArticles = ({data}) => {
  const models = data.allBuilderModels
  const page = models.page[0].data
  const articles = models.article

  return <>
  <Helmet>
    <title>Latest Articles</title>
    <meta property="og:url" content={`https://www.watchesguild.com/articles/`} />
    <meta property="og:description" content={page.description} />
    <meta name="description" content={page.description} />
    <link rel="canonical" href={`https://www.watchesguild.com/articles/`}/>
    <script type="application/ld+json">
      {`
      {"@context":"http://schema.org","@type":"BreadcrumbList","itemListElement":[
        {"@type":"ListItem","position":1,"item":{"@id":"https://www.watchesguild.com/articles","name":"Articles"}},
      ]}
      `}
    </script>
  </Helmet>
  <main className="pageArticles stdPage"
    style={{
      margin: `0 auto`,
    }} >
    <div class="heroSection">
      <div class="heroContainer">
        <BreadCrumb pageName="Articles" />
        <Container className="imageContainer container">
          <Title>{page.title}</Title>
          <p>{page.description}</p>
        </Container>
      </div>
    </div>
    <div class="container">
      <ArticlesContainer className="Articles">
        <Articles articles={articles} />
      </ArticlesContainer>
    </div>
    <Newsletter placeholder="Your email here" submit="Subscribe" />
  </main>
</>
};

export default LatestArticles

export const pageQuery = graphql`
  query {
    allBuilderModels {
      page(
        target: { urlPath: "/articles/" }
        limit: 1
        options: { cachebust: true }
      ) {
        data {
          title
          description
          template
          url
        }
      }
      article(limit: 15, sort: {createdDate : DESC}) {
        data {
          title
          slug
          summary
          picture
        }
        createdDate
      }
    }
  }
`;